import React from 'react'
import Innovations from '../components/innovations'
import InnovationJSON from "../content/innovations.json"

export default function sepp() {
    const content = InnovationJSON.innovations[0]
    return (
        <Innovations content={content} />
    )
}
