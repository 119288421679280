import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "./layout"
import Seo from "./seo"
import "../styles/bootstrap.scss"

export default function innovations({content}) {
    const seppPage = content.title.toLowerCase() === "sepp" ? true : false

    function innovationImages(name, banner) {
        switch(name) {
            case "sepp":
                if(banner) {
                    return <img className="parallax sepp" src="/wireless-city.jpeg" alt={name}/>
                }
                return <img style={{objectFit: 'contain'}} src="/sepp_logo.jpg" alt={name}/>
            case "wap":
                if(banner) {
                    return <img className="parallax wap" src="/wap.jpg" alt={name}/>
                }
                return <img style={{height: '40vh', objectFit: 'contain'}} src="/wap_logo.png" alt={name}/>
            case "rise":
                if(banner) {
                    return <img className="parallax rise" src="/rise-cover.jpg" alt={name}/>
                }
                return <img style={{objectFit: 'contain'}} src="/rise-logo.jpg" alt={name}/>
            default:
                return <div />
        }
    }

    return (
        <Layout>
            <Seo title={content.title.toUpperCase()} />
            {innovationImages(content.title.toLowerCase(), true)}
            <div className="banner-cover" />
            <div className="banner animate__animated fadeInDownSmall">
                <h1 className="display-3"><b>{content.title}</b></h1>
            </div>
            <Container fluid className="bg-white" style={{padding: '3% 0 0', boxShadow: 'inset 0 5px 5px #bebebe'}}>
                <Container className="mt-0">
                    <Row style={{whiteSpace: "pre-wrap"}}>
                        <Col lg={4} className="d-flex justify-content-center mb-4">
                            {innovationImages(content.title.toLowerCase())}
                        </Col>
                        <Col lg={8} className="my-auto">
                            <h5>{content.subheader}</h5>
                            <br/>
                            <p>{content.description}</p>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="bg-light" style={{padding: "0 15%"}}>
                    {seppPage &&
                        <div className="content-list">
                            <div className="embed-responsive embed-responsive-16by9 w-75 mx-auto">
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube-nocookie.com/embed/pDPlVerbVRo?rel=0"
                                    title="Trabus Technologies SEPP Video"
                                    frameBorder="0"
                                    allowFullScreen
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                ></iframe>
                            </div>
                        </div>
                    }
                    {content.items.map( (item, index) => (
                        <div className={"content-list " + (index === content.items.length-1 ? "last" : "")} key={index}>
                            <h5>{item.title}</h5>
                            <p>{item.description}</p>
                            <ul>
                                {item.list.map( (list, index) => (
                                    <li className="my-2" key={index}>{list}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </Container>
            </Container>
        </Layout>
    )
}
